@media screen and (max-width:900px) {

    .village-page>p {
        text-align: center;
    }

    .village-page-showoff {
        width: 80%;
    }
}

@media screen and (max-width:1500px) {

    .village-page-villages {
        max-width:90%;
    }
}