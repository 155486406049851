.spring-news {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
}

.spring-news-startup {
  position: relative;
  width: 60%;
  height: 26rem;
  overflow: hidden;
  z-index: 1;
}

.spring-news-startup > div {
  position: absolute;
  width: 100%;
  justify-content: center;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.spring-news-startup > div > iframe {
  width: 60%;
  height: 20rem;
}

.spring-news > p {
  font-size: 2.5rem;
  color: rgb(4, 4, 4);
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.spring-news-startup-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  z-index: 5;
}

.spring-news-startup-buttons > button {
  border: none;
  background: none;
  cursor: default;
}

.spring-news-startup-buttons > button > svg {
  width: 2.5rem;
  cursor: pointer;
}

.spring-news-startup-buttons > button > svg:hover {
  opacity: 0.8;
  cursor: pointer;
}

.spring-news-startup-buttons > #startup-previous > svg {
  transform: rotate(180deg);
}

.spring-startup-selected {
  display: flex !important;
}

.spring-news > svg {
  position: absolute;
  z-index: 0;
}

.homepage-article-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22rem;
  z-index: 10;
  box-shadow: 0px 0px 8px rgb(0, 0, 0, 0.2);
  position: relative;
  height: 14rem;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
}

.homepage-article-card > img {
  width: 100%;
  position: absolute;
}

.homepage-article-card > p {
  z-index: 15;
  width: 100%;
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 0;
  color: black;
  background: rgba(255, 255, 255, 0.768);
  padding: 0.5rem;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}

.homepage-article-card:hover > p {
  padding-bottom: 2rem;
  background: white;
  transition: all 0.2s ease-in-out;
}

.homepage-article-cards {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
  column-gap: 4rem;
  flex-wrap: wrap;
}

.spring-news > p {
  z-index: 10;
}

.spring-newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  background-color: white;
  box-shadow: 5px 5px 20px rgb(0, 0, 0, 0.2);
  width: 40%;
  margin-top: 8rem;
  margin-bottom: 0rem;
}

.spring-newsletter > .button-classic {
  background: var(--red);
  box-shadow: 4px 4px 0px var(--red-darker);
}

.spring-newsletter > .button-classic:hover {
  box-shadow: none;
}

.spring-newsletter > p:nth-child(1) {
  font-size: 2.5rem;
  width: 55%;
  line-height: 2.8rem;
  text-align: center;
}

.spring-newsletter > p:nth-child(2) {
  margin: 1rem;
}

.homepage-infos {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-infos > p:nth-child(1) {
  font-size: 2.5rem;
  margin-bottom: 4rem;
  margin-top: 8rem;
}

.homepage-info-cards {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 2rem;
  width: 100%;
  height: 20rem;
  padding-top: 1rem;
  overflow: hidden;
}

.homepage-info-card {
  position: absolute;
  width: 14rem;
  height: 12rem;
  background: white;
  padding: 2rem;
  box-shadow: 5px 5px 20px rgb(0, 0, 0, 0.2);
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
}

.homepage-info-card > p:nth-child(1) {
  color: var(--blue-middle);
  font-size: 3.2rem;
  font-weight: 900;
  margin-left: -0.5rem;
}

.homepage-info-card > p:nth-child(1) > span {
  display: hidden;
  counter-set: num var(--num);
}

.homepage-info-card > p:nth-child(2) {
  color: black;
  font-size: 1.2rem;
  font-weight: 900;
  margin-top: -0.5rem;
}

.homepage-info-card > p:nth-child(3) {
  color: rgb(43, 43, 43);
  font-size: 1.2rem;
  font-weight: 500;
}

.homepage-info-cards-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.homepage-info-cards-buttons > button {
  border: none;
  background: none;
}

.homepage-info-cards-buttons > button:not(.blocked):hover {
  opacity: 0.8;
  cursor: pointer;
}

#infos-previous > svg {
  transform: rotate(180deg);
}

.homepage-global-partners {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-global-partners > p {
  font-weight: 700;
  font-size: 2rem;
  position: relative;
  margin-top: 6rem;
}

.homepage-global-partners > p::after {
  content: "";
  position: absolute;
  width: 80rem;
  left: calc(50% - 40rem);
  bottom: -0.75rem;
  height: 2px;
  background: rgba(93, 93, 93, 0.228);
}

.homepage-co-organisators {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 45%;
  row-gap: 2rem;
  column-gap: 2rem;
}

.homepage-co-organisators > img {
  max-width: 20%;
  max-height: 10rem;
}

.homepage-ambassadors {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 45%;
  row-gap: 2rem;
  column-gap: 2rem;
  margin-top: 2rem;
}

.homepage-ambassadors > img {
  max-width: 30%;
}

.homepage-partners {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 45%;
  row-gap: 1rem;
  column-gap: 2rem;
  margin-top: 2rem;
}

.homepage-partners > img {
  max-width: 28%;
  max-height: 8rem;
}
