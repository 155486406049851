.legal-mentions-page {
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50vh;
}

.legal-mentions-title {
    font-weight: 600;
    font-size: 3rem;
}

.legal-mentions-list {
    width: 50%;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.legal-mention>p:nth-child(1) {
    font-weight: 600;
    font-size: 2rem;
    margin-left: -1rem;
}