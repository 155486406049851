.press-page {
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.press-title {
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 5rem;
}

.press-page-contact>p>span:nth-child(1) {
    font-weight: 600;
    font-size: 1.5rem;
}

.press-page-contact>p>span:nth-child(2) {
    font-size: 1.5rem;
}

.press-page-contact>a {
    color: black;
    font-size: 1.2rem;
}

.press-page-contact>a>p {
    margin-left: 1rem;
}

.press-page-contacts {
    margin-bottom: 4rem;
}

.press-page>.button-classic {
    margin-bottom: 5rem;
}