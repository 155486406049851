@media screen and (max-width: 900px) {
  .spring50-showoff {
    width: 80%;
  }

  .spring50-showoff p {
    text-align: center;
  }

  .spring-news > svg {
    display: none;
  }
}

@media screen and (max-width: 1500px) {
  .village-page-villages {
    max-width: 90%;
  }
}
