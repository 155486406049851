@media screen and (max-width:900px) {

    .circuit-page-title {
        text-align: center;
    }

    .circuit-page-showoff {
        width: 80%;
    }

    .circuit-page-showoff > p > strong{
        width:100%;
        display:block;
    }

    .circuit-page-circuits {
        width: 90%;
    }

    .circuit-card {
        width: 21rem;
        height:38rem;
    }

    .circuit-card-content>img {
        width: 19rem;
        margin-top: 1rem;
        margin-left:0rem;
    }

    .circuit-card-sub {
        width: 95%;
    }
}