@media screen and (max-width:900px) {
    .footer {
        height: 25rem;
    }

    .footer>img:nth-child(1) {
        margin-bottom: -5rem;
        ;
    }

    .footer>img:nth-child(2) {
        bottom: 10rem;
    }

    .footer-infos {
        gap: 2rem;
    }

    .footer-left>p {
        font-size: 0.8rem;
    }

    .footer-left>a>img {
        max-width: 1.5rem;
    }

    .footer-right>a>p {
        font-size: 1rem !important;
    }

    .footer-right>p {
        font-size: 0.5rem !important;
    }
}