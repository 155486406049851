@media screen and (max-width:900px) {

    .legal-mentions-list{
        width:85%;
    }

    .legal-mentions-title{
        text-align: center;
    }

    .legal-mention > p:nth-child(1){
        margin-left:-0.5rem;
    }

}