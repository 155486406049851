.cls-1 {
  fill: #ed6e1d;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9,
.cls-10,
.cls-11 {
  strokeWidth: 0px;
}

.cls-2 {
  fill: #fcbd0e;
}

.cls-3 {
  fill: #bb82b8;
}

.cls-4 {
  fill: #e83d3c;
}

.cls-5 {
  fill: none;
}

.cls-6 {
  fill: #e82f89;
}

.cls-7 {
  fill: #046498;
}

.cls-8 {
  fill: #fff;
}

.cls-9 {
  fill: #008bc5;
}

.cls-10 {
  fill: #66bfb1;
}

.cls-12 {
  clip-path: url(#clippath);
}

.cls-11 {
  fill: #563a44;
}

#clippath>path {
  animation: clipAnim 1s ease-in-out 1s forwards;
  d: path("m99.91,986.62c447.39,428.67,1172.74,428.67,1620.11,0,447.39-428.64,447.39-1123.67,0-1552.32-447.37-428.69-1172.72-428.69-1620.11,0-447.38,428.65-447.38,1123.68,0,1552.32Z");
}



#f1 {
  transform: translateX(-200rem);
  animation: release 2s ease-in-out forwards;
}

#f2 {
  transform: translateX(-300rem);
  animation: release 2s ease-in-out forwards;
}

#f3 {
  transform: translateX(-200rem);
  animation: release 2s ease-in-out forwards;
}

#f4 {
  transform: translateX(-300rem);
  animation: release 2s ease-in-out forwards;
}

#f5 {
  transform: translateX(-200rem);
  animation: release 2s ease-in-out forwards;
}

#f6 {
  transform: translateX(-200rem);
  animation: release 2s 0.2s ease-in-out forwards;
}

#f7 {
  transform: translateX(250rem);
  animation: release 2s ease-in-out forwards;
}

#f8 {
  transform: translateX(400rem);
  animation: release 2s ease-in-out forwards;
}

#f9 {
  transform: translateX(-280rem);
  animation: release 2s ease-in-out forwards;
}

#f10 {
  transform: translateX(-320rem);
  animation: release 2s ease-in-out forwards;
}

#f11 {
  transform: translate(-200.19px, -931.87px) rotate(-135deg);
  animation: release11 2s 0.5s ease-in-out forwards;
}

#f12 {
  transform: translate(3544.69px, 2608.12px) rotate(-135deg);
  animation: release12 2s 0.5s ease-in-out forwards;
}

#f13 {
  transform: translate(-27.75px, -861.88px) rotate(-135deg);
  animation: release13 2s 0.5s ease-in-out forwards;
}

#f14 {
  transform: translate(084.72px, 2006.46px) rotate(135deg);
  animation: release14 2s ease-in-out forwards;
}

#f15 {
  transform: translate(4039.84px, -2153.55px) rotate(135deg);
  animation: release15 2.5s ease-in-out forwards;
}

#f16 {
  transform: translate(112.28px, 1876.45px) rotate(135deg);
  animation: release16 2.5s ease-in-out forwards;
}

#Texte {
  opacity: 0;
  animation: texte 0.4s ease-in-out 1.5s forwards;
}

@keyframes release {
  to {
    transform: none;
  }
}

@keyframes release11 {
  to {
    transform: translate(1299.81px, 568.13px) rotate(-135deg);
  }
}

@keyframes release12 {
  to {
    transform: translate(1444.69px, 508.12px) rotate(-135deg)
  }
}

@keyframes release13 {
  to {
    transform: translate(1372.25px, 538.13px) rotate(-135deg);
  }
}

@keyframes release14 {
  to {
    transform: translate(1684.72px, 406.46px) rotate(135deg);
  }
}

@keyframes release15 {
  to {
    transform: translate(1539.84px, 346.45px) rotate(135deg);
  }
}

@keyframes release16 {
  to {
    transform: translate(1612.28px, 376.45px) rotate(135deg);
  }
}

@keyframes texte {
  to {
    opacity: 1;
  }
}

@keyframes clipAnim {
  from {
    d: path("m99.91,986.62c447.39,428.67,1172.74,428.67,1620.11,0,447.39-428.64,447.39-1123.67,0-1552.32-447.37-428.69-1172.72-428.69-1620.11,0-447.38,428.65-447.38,1123.68,0,1552.32Z");
  }

  to {
    d: path("m806.96,307.47c53.58,53.58,140.46,53.58,194.04,0,53.58-53.58,53.58-140.46,0-194.04-53.58-53.59-140.46-53.59-194.04,0-53.58,53.58-53.58,140.46,0,194.04Z")
  }
}