@media screen and (max-width:900px) {
    .event-page-title {
        font-size: 2rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .event-page-summary {
        gap: 1rem;
        margin-left: 10%;
    }

    .event-day-summary {
        width: 50%;
    }

    .event-page-list {
        width: 80%;
    }

    .event-page-list>p>span {
        font-size: 1.5rem !important;
    }

    .event-page-list>p>span:nth-child(2) {
        margin-left: 1rem;
    }

    .event-page-day {
        padding-left: 1rem;
    }

    .event-page-place {
        padding-left: 1.5rem;
    }

    .event-item-infos {
        margin-left: -2.5rem;
        z-index: 10;
    }

    .event-card-speakers .speaker-pin-infos {
        font-size: 0.7rem;
    }

    .event-card-speakers .speaker-pin-picture {
        width: 2.5rem;
        height: 2.5rem;
    }

    .event-card-speakers .speaker-pin-picture img {
        max-width: 2.5rem;
        max-height: 2.5rem;
    }

    .event-item-companies {
        gap: 1rem;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 0rem;
    }

    .event-item-companies>img {
        width: 25%;
    }
}