.login-page {
  padding-top: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 40vh;
}

.login-page > .markdown-text {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .login-page > .markdown-text {
    width: 90%;
  }
}
