.navbar {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.9rem;
    position: fixed;
    z-index: 150;
    background: white;
    position: fixed;
    width: 100%;
}

.navbar-left {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.navbar-left>img {
    max-width: 4rem;
    max-height: 4rem;
    cursor: pointer;
}

.navbar-pages {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navbar>img {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -0.4rem;
}

.navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100%);
    padding: 0.75rem;
    padding-left: 1.25rem;
}

.navbar-left > img:nth-child(2){
    display:none;
}

.navbar-right>.button-classic {
    font-size: 1rem;
    margin-left: 1rem;
}

.navbar-right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navbar-right>p:not(:first-child) {
    font-weight: 600;
    font-size: 1.1rem;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.591);
    position: relative;
}

.navbar-right>p:first-child {
    font-weight: 500;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.591);
}

.navbar-selected {
    color: black !important;
}

.navbar-selected::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: black;
    bottom: 0;
    left: 0;
}

.contact-pin>a>img {
    width: 3rem;
    height: 3rem;
}

.contact-pin {
    position: absolute;
    border-radius: 50%;
    background-color: white;
    border: 2px solid black;
    padding: 0.25rem;
    right: 2rem;
    top: 8rem;
}

.contact-pin:hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.22);
}

.contact-pin:hover>a>img {
    animation: contactPin 0.75s cubic-bezier(0.51, 0, 0.8, 1.2) forwards;
}

@keyframes contactPin {

    25%,
    75% {
        transform: rotate(20deg);
    }

    50% {
        transform: rotate(-20deg);
    }

    100% {
        transform: rotate(0)
    }

}