.highlightable-text {
    cursor: pointer;
    position: relative;
}

.highlightable-text-selected {
    color: #008cc9;
}

.highlightable-text:hover {
    color: #008cc9;
}

.button-classic {
    font-size: 1.3rem;
    font-weight: 900;
    color: white;
    background: var(--blue);
    padding: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 4rem;
    box-shadow: 4px 4px 0px var(--blue-darker);
    transform: translate(-4px, -4px);
    transition: all 0.1s ease-in-out
}

.button-classic:hover {
    transform: none;
    box-shadow: none;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}