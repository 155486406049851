.spring-news-startup>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 26rem;
}

.spring-news-startup>div>p {
    color: black;
    z-index: 10;
    padding: 1rem;
}

.spring-news-startup>div>p:nth-child(1) {
    font-weight: 700;
    font-size: 1.5rem;
}