@media screen and (max-width:900px) {

    .homepage-news,
    .homepage-news-buttons,
    .homepage-logos {
        display: none;
    }

    .homepage {
        padding-top: 2rem;
    }

    .spring-presentation-title {
        width: 85%;
        margin-top: 2rem;

        margin-bottom: 1rem;
    }

    .spring-presentation-title>p:nth-child(1) {
        font-size: 2.4rem !important;
        line-height: 2.4rem !important;
        font-weight: 900 !important;
        text-align: center;
    }

    .spring-presentation-title>p:nth-child(2) {
        font-size: 1.2rem !important;
        line-height: 1.2rem !important;
        font-weight: 700 !important;
        text-align: center;
        width: 100%;
        margin-top: 0.5rem;
    }

    .spring-presentation>img:not(.presentation-logo) {
        display: none;
    }

    .presentation-logo {
        width: 80%;
        display: block;
    }

    .spring-presentation>p {
        width: 85%;
        margin-top: 5rem;
        text-align: center;

    }

    .spring-presentation {
        padding-bottom: 2rem;
        ;
    }

    .spring-presentation>.button-classic {
        font-size: 1.2rem;
    }

    .spring-newsletter {
        width: 75% !important;
        padding: 2rem !important;
        margin-top: 4rem !important;
    }

    .spring-newsletter>p:nth-child(1) {
        font-size: 1.5rem !important;
        width: 80% !important;
        line-height: 1.8rem !important;
        font-weight: 700;
    }

    .spring-newsletter>p:nth-child(2) {
        text-align: center;
    }

    .spring-newsletter>.button-classic {
        font-size: 1.2rem;
    }

    .homepage-infos>p:nth-child(1) {
        margin-top: 3rem !important;
        margin-bottom: 2rem !important;
        font-size: 2rem !important;
    }

    .homepage-info-cards {
        height: 18rem !important;
    }

    .homepage-info-card {
        width: 10rem !important;
        height: 10rem !important;
    }

    .homepage-info-card>p:nth-child(1) {
        font-size: 2.5rem !important;
    }

    .homepage-global-partners>p {
        text-align: center;
        font-size: 1.5rem !important;
        margin-top: 3rem !important;
    }

    .homepage-global-partners>p::after {
        content: none !important;
    }

    .homepage-co-organisators {
        width: 80% !important;
    }

    .homepage-co-organisators>img {
        max-width: 30% !important;
    }

    .homepage-ambassadors {
        width: 80% !important;
        row-gap: 0.5rem !important;
        column-gap: 2rem !important;
    }

    .homepage-ambassadors>img {
        max-width: 45% !important;
    }

    .homepage-partners {
        width: 80% !important;
    }

    .homepage-partners>img {
        max-width: 25% !important;
    }
}