.event-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 20rem;
  min-height: 100vh;
  overflow-x: hidden;
}

.event-page > img {
  margin-top: 3rem;
  width: 100%;
}

.event-page-day-place {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  gap: 0.2rem;
  position: relative;
  margin-top: 1rem;
}

.event-page-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  position: relative;
}

.event-card-speakers {
  position: relative;
  margin-top: 1rem;
  padding: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
}

.event-card-speakers > .speaker-pin {
  width: calc(50% - 2rem);
}

.event-card-speakers:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: -0.5rem;
  background-color: rgb(125, 125, 125);
  border-radius: 1rem;
}

.event-flag {
  display: flex;
  align-items: center;
  padding: 0.2rem;
  gap: 0.5rem;
  cursor: pointer;
}

.speaker-pin {
  display: flex;
  white-space: nowrap;
  justify-content: center;
}

.speaker-pin-infos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0.75rem;
}

.speaker-pin-infos > p:nth-child(1) {
  font-weight: 800;
}

.speaker-pin-infos > p:nth-child(2) {
  font-weight: 600;
  color: rgb(108, 108, 108);
  margin-left: 0.8rem;
  text-wrap: wrap;
}

.speaker-pin-picture {
  overflow: hidden;
  border-radius: 10rem;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
}

.speaker-pin-picture > img {
  max-width: 3rem;
  max-height: 3rem;
}

.event-item-infos {
  background-color: rgb(255, 255, 255);
  padding: 0;
  margin-left: 3rem;
  display: none;
  box-shadow: 0px 0px 10px 1px #0000003d;
  margin-top: 0.5rem;
  overflow: hidden;
  position: relative;
}

.event-item-infos > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.4rem;
  width: 100%;
}

.event-info-date {
  font-weight: 900;
}

.event-flag svg {
  transition: all 0.2s ease-in-out;
}

.event-flag > p {
  transition: all 0.2s ease-in-out;
  font-weight: 900;
}

.event-flag:hover > p {
  transform: translateX(0.75rem);
  transition: all 0.2s ease-in-out;
}

.event-selected svg {
  transform: rotate(90deg);
  transition: all 0.2s ease-in-out;
}

.event-selected > .event-item-infos {
  display: block;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.event-item {
  position: relative;
}

.event-flag:after {
  content: "";
  background-color: var(--blue);
  width: 8px;
  height: 8px;
  border-radius: 20px;
  position: absolute;
  left: -0.7rem;
  top: calc(0.4rem + 4px);
  z-index: 2;
}

.event-info-date-float {
  position: absolute;
  left: -4rem;
  top: 0.2rem;
}

.event-page-day-place:after {
  content: "";
  position: absolute;
  height: calc(100% + 0.5rem);
  width: 2px;
  background-color: var(--blue);
  top: -0.5rem;
  left: -0.5rem;
  border-radius: 2rem;
}

.event-page-day-place:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 2rem;
  top: -0.5rem;
  left: -0.5rem;
  border-radius: 2rem;
  background-color: var(--blue);
}

.event-page-list > p {
  width: 100%;
  margin-bottom: 0.75rem;
}

.event-page-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 3rem;
  margin-top: 2rem;
}

.event-page-summary {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
  margin-bottom: 4rem;
}

.event-day-summary {
  position: relative;
  width: 50%;
  flex-basis: 100%;
}

.event-day-summary > p {
  margin-bottom: 1.25rem;
  margin-left: -1.5rem;
  font-weight: 800;
  white-space: nowrap;
  font-size: 1.5rem;
}

.event-day-data {
  position: relative;
}

.event-day-data:before {
  content: "";
  position: absolute;
  height: calc(100% + 1rem);
  width: 2px;
  background-color: var(--blue);
  top: -0.5rem;
  left: -0.75rem;
}

.event-day-data-item > p {
  color: var(--blue);
  font-weight: 900;
  font-size: 1.4rem;
}

.event-day-data-subs {
  position: relative;
  margin-left: 0.3rem;
}

.event-day-data-subs > p:before {
  content: "•";
  position: absolute;
  left: -0.75rem;
}

.event-day-first:after,
.event-day-last:after {
  content: "";
  position: absolute;
  left: -0.75rem;
  width: 2rem;
  height: 2px;
  background-color: var(--blue);
}

.event-day-first:after {
  top: -0.5rem;
}

.event-day-last:after {
  bottom: -0.5rem;
}

.event-item-infos-solid {
  display: flex;
  padding: 0;
}

.event-item-infos-solid > img {
  height: 16rem;
}

.event-item-infos-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
}

.event-page-title {
  font-weight: 600;
  font-size: 3rem;
  background-color: var(--yellow);
  padding: 0rem 5rem 0rem 5rem;
  color: white;
  box-shadow: 6px 6px 0px 0px var(--yellow-darker);
  margin-bottom: 2rem;
}

.event-page-list > p > span:nth-child(1) {
  font-size: 1.8rem;
  font-weight: 900;
  color: white;
  padding: 0rem 0.5rem 0rem 0.5rem;
  background-color: var(--yellow);
  box-shadow: 4px 4px 0px 0px var(--yellow-darker);
  position: relative;
  z-index: 4;
}

/* .event-page-list>p>span:nth-child(1):after {
    content: '';
    background-color: var(--yellow);
    width: 1.5rem;
    height: 3px;
    position: absolute;
    right: -2.25rem;
    top: 1.2rem;
} */

.event-page-list > p > span:nth-child(2) {
  font-size: 1.8rem;
  font-weight: 900;
  margin-left: 2rem;
  padding: 0 0.8rem 0 0.8rem;
  position: relative;
  color: white;
  background-color: var(--blue);
  box-shadow: 4px 4px 0px 0px var(--blue-darker);
  z-index: 4;
}

/* .event-page-list>p>span:nth-child(2):after {
    content: '';
    background-color: var(--yellow);
    width: 100%;
    height: 3px;
    position: absolute;
    top: calc(50% - 2px);
    right: calc(-100% - 1rem);
} */

.event-page-list:after {
  content: "";
  position: absolute;
  width: calc(100% + 10rem);
  height: 3px;
  top: 1.25rem;
  left: -5rem;
  background-color: var(--yellow);
  z-index: 0;
  border-radius: 2rem;
}

.event-info-date {
  margin-left: 0rem;
}

.event-info-place {
  margin-left: 0rem;
}

.event-page-day {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  margin-top: 1rem;
}

.event-page-day-desc {
  margin-bottom: -0.75rem;
  margin-left: -1rem;
}

.event-page-day-desc > p:nth-child(1),
.event-page-village-desc > p:nth-child(1) {
  font-weight: 900;
  font-size: 1.5rem;
}

.event-page-village-desc {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  margin-left: -1rem;
}

.event-page-place > p {
  font-size: 1.8rem;
  font-weight: 900;
  color: var(--black);
}

.event-page-villages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
}

.company-flag > p {
  font-size: 1.2rem;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.company-flag {
  display: flex;
  align-items: center;
}

.company-flag > svg {
  transition: all 0.2s ease-in-out;
}

.company-flag:hover > p {
  transition: all 0.2s ease-in-out;
  transform: translateX(0.5rem);
}

.event-page-village-company > p:hover:before {
  transform: translateX(-0.5rem);
}

.village-company-data {
  display: none;
  margin-left: 1rem;
}

.company-selected > .village-company-data {
  display: block;
}

.company-selected > .company-flag > svg {
  transform: rotate(90deg);
  transition: all 0.2s ease-in-out;
}

.village-company-data > div > p > span:nth-child(1) {
  color: red;
}

.village-company-data > div > p > span:nth-child(2) {
  font-weight: 800;
  color: rgb(51, 51, 51);
}

.village-company-data > div > p:nth-child(2) {
  color: rgb(72, 72, 72);
  padding-left: 0.5rem;
}

.village-company-data > div:not(:nth-child(1)) {
  margin-top: 0.5rem;
}

.event-page-village > p {
  font-size: 1.4rem;
  font-weight: 800;
}

.event-page-village-companies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.event-page-village-day {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.event-item-companies {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.event-item-companies > img {
  width: 18%;
}
