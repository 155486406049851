@media screen and (max-width:900px) {

    .infopage-map {
        width: 95%;
        height: 35rem;
    }

    .infopage-contacts {
        width: 90%;
    }

    .infopage>p {
        text-align: center;
        width:75%;
    }

    .infopage-mainplan > img{
        max-width:90%;
    }
    
    .infopage-mainplan > div{
        width:85%;
    }
}