p {
  font-family: var(--font-family);
}

html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
}

select {
  border: none;
  outline: none;
  color: black;
  background: white;
  outline: 1px solid black;
  padding: 0.5rem;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 800;
  border-radius: 4rem;
}

select:hover {
  background: black;
  color: white;
  cursor: pointer;
}

li {
  font-family: var(--font-family);
}

.bold-text {
  color: rgb(0, 0, 0);
  font-weight: 900;
  font-size: 1.2rem;
}
