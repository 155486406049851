.cls_sub-1 {
  mix-blend-mode: multiply;
}

.cls_sub-1,
.cls_sub-2,
.cls_sub-3,
.cls_sub-4,
.cls_sub-5,
.cls_sub-6,
.cls_sub-7,
.cls_sub-8 {
  strokeWidth: 0px;
}

.cls_sub-1,
.cls_sub-3 {
  fill: #e63336;
}

.cls_sub-2 {
  fill: #008ac4;
}

.cls_sub-9 {
  isolation: isolate;
}

.cls_sub-4 {
  fill: #056497;
}

.cls_sub-5 {
  fill: none;
}

.cls_sub-6 {
  fill: #1e1e1c;
}

.cls_sub-7 {
  fill: #c52e2b;
}

.cls_sub-8 {
  fill: #563a44;
}