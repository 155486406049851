@media screen and (max-width:900px) {

    .spring-news > p{
        text-align: center;
        width:90%;
        font-weight: 600;
    }

    .spring-news-startup>div>iframe{
        width:100%;
    }

    .spring-news-startup{
        width:85%;
    }

    .homepage-article-cards{
        width:90%;
    }
}