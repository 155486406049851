.homepage {
    padding-top: 5.5rem;
    position: relative;
}

.homepage-logos>div:nth-child(1) {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    min-width: 100%;
    min-height: 22rem;
    z-index: 3;
}

.homepage-logos>div:nth-child(1)>svg {
    animation: slideLeft 1s ease-in-out 1.5s forwards;
    min-height: 22rem;

}

.homepage-logos>div:nth-child(2) {
    position: absolute;
    overflow: hidden;
    display: flex;
    left: 50%;
    top: 15rem;
    animation: slideRight 1s ease-in-out 1.5s forwards;
    opacity: 0;
}

.homepage-logos>div:nth-child(2)>svg {
    width: 16rem;
}

.homepage-logos {
    position: absolute;
    width: 100%;
    animation: logoMoveUp 0.75s ease-in-out 3.25s forwards;
    z-index: 6;
}

@keyframes slideLeft {
    to {
        transform: translateX(-8rem);
    }
}

@keyframes slideRight {
    100% {
        opacity: 1;
        transform: translateX(1.5rem);
    }
}

@keyframes logoMoveUp {
    100% {
        transform: translate(0, -10rem) scale(0.4);
        opacity: 0;
    }
}

@keyframes appear {
    100% {
        opacity: 1;
    }
}

.homepage-news-item {
    position: absolute;
    width: 100%;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
}

.homepage-news-item>img {
    width: 40%;
}

.homepage-news {
    opacity: 0;
    overflow: hidden;
    position: relative;
    height: 25rem;
    padding: 3rem;
    animation: appear 0.75s ease-in-out 3.5s forwards;
    margin-bottom: 2rem;
}

.homepage-news-item-data {
    padding-top: 4rem;
    width: 50%;
    height: 17rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homepage-news-item-data>p:nth-child(1) {
    font-weight: 600;
    font-size: 2rem;
    width: 80%;
}

.homepage-news-title>p:nth-child(1) {
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: 600;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background-color: var(--blue);
    color: white;
    box-shadow: 4px 4px 0px 0px var(--blue-darker);
}

.homepage-news-item-loc {
    margin-left: 2rem;
    margin-top: 2rem;
    display: flex;
    gap: 0.5rem;
    font-size: 1.5rem;
}

.homepage-news-item-data>p {
    margin-left: 4rem;
    margin-top: 2rem;
}

.homepage-news-more {
    margin-left: 4rem;
    margin-top: 3rem;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

.homepage-news-more>svg {
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    transition: all 0.2s ease-in-out;
}

.homepage-news-more>p {
    border-bottom: 2px solid black;
}

.homepage-news-more:hover>svg {
    transform: translateX(0.25rem);
}

.homeapge-news-more:hover>p {
    color: rgb(35, 35, 35);
}

.homepage-news-buttons>button {
    border: none;
    background-color: var(--blue);
    padding: 0.25rem;
    border-radius: 2rem;
}

.homepage-news-buttons svg {
    transform: translate(0.2rem, 0.1rem) scale(0.8);
}

.homepage-news-buttons>button:nth-child(1)>svg {
    transform: translate(-0.2rem, 0.1rem) rotate(180deg) scale(0.8);
}

.homepage-news-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    animation: appear 0.75s ease-in-out 3.5s forwards;
    opacity: 0;
}

.homepage-news-buttons>button:not(.blocked):hover {
    cursor: pointer;
    opacity: 0.8;
}

.blocked {
    opacity: 0.5;
}

.homepage>img {
    height: 3rem;
    width: 100%;
    margin-top: 3rem;
}

.spring-presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 8rem;
}

.spring-presentation-logo {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 4rem;
    justify-content: center;
    margin-top: 4rem;
}

.spring-presentation-logo>img:nth-child(1) {
    width: 40%;
}

.spring-presentation-logo>img:nth-child(2) {
    max-width: 50%;
    max-height: 12rem;
}

.presentation-logo {
    display: none;
}

.spring-presentation-title {
    width: 50%;
    margin-top: 18rem;
    margin-bottom: 2rem;
}

.spring-presentation-title>p:nth-child(1) {
    color: var(--blue-middle);
    font-weight: 900;
    font-size: 5rem;
    text-wrap: wrap;
    line-height: 4.5rem;
}

.spring-presentation-title>p:nth-child(2) {
    font-size: 2rem;
    margin-top: 1.5rem;
}

.homepage-divider {
    border-radius: 0.25rem;
    width: 50%;
    height: 1rem;
}

.spring-presentation>p {
    width: 50%;
    font-weight: 500;
    font-size: 1.3rem;
    color: rgb(64, 64, 64);
    margin-top: 0.5rem;
    margin-bottom: 4rem;
}

#float1 {
    position: absolute;
    max-width: 22%;
    left: -4rem;
    top: 8rem;
}

#float2 {
    position: absolute;
    max-width: 16%;
    min-width: 16%;
    right: 1rem;
    top: 30%;
}

#float3 {
    position: absolute;
    max-width: 18%;
    left: 4%;
    top: 40rem;
}

#float4 {
    position: absolute;
    width: 10%;
    left: 30%;
}

#float5 {
    position: absolute;
    width: 16%;
    top: -2rem;
    right: 16%;
}

.spring-presentation>svg:nth-child(1) {
    position: absolute;
    margin-top: -40rem;
    z-index: 0;
}

.spring-presentation>svg:nth-child(2) {
    position: absolute;
    margin-top: 2rem;
}

.spring-presentation>*:not(svg) {
    z-index: 2;
}