.circuit-page {
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 35vh;
}

.circuit-page-title {
  font-weight: 600;
  font-size: 3rem;
}

.circuit-page-circuits {
  width: 55%;
  padding: 2rem;
  display: flex;
  row-gap: 2rem;
  column-gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.circuit-page-showoff {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 4rem;
}

.circuit-card {
  box-shadow: 5px 5px 20px rgb(0, 0, 0, 0.2);
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 46rem;
  position: relative;
}

.circuit-card-content {
  width: calc(100% - 4rem);
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: -2rem;
}

.circuit-card-content > img {
  width: 26rem;
}

.circuit-card > img {
  width: 100%;
}

.circuit-card-content > p {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.circuit-card-sub > p:nth-child(1) {
  font-size: 1.2rem;
  color: rgba(93, 93, 93, 0.656);
  margin-top: 0.5rem;
}

.circuit-card a {
  color: black;
}

.circuit-card-sub > a > p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.circuit-card-website {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 2rem;
  transition: all 0.2s ease-in-out;
}

.circuit-card-website > a {
  font-weight: 800;
  text-decoration: none;
}

.circuit-card-website > img {
  width: 1.5rem;
  height: 1.5rem;
}

.circuit-card > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.circuit-card-website:hover {
  transform: translateY(-0.5rem);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.circuit-card-website:hover > img {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}
