.village-page {
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.village-page > p:nth-child(1) {
  font-weight: 600;
  font-size: 3rem;
}

.village-page-showoff {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  margin-bottom: 2rem;
  margin-top: 4rem;
}

.village-page-villages {
  display: flex;
  align-content: stretch;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 1.25rem;
  column-gap: 1.25rem;
  margin-top: 1rem;
  max-width: calc(100% - 40rem);
  padding-bottom: 1rem;
}

.village-card {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.162);
  width: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 38rem;
}

.village-card-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.village-icon {
  width: 15rem;
}

.village-up {
  width: 17rem;
}

.village-title {
  font-size: 1.5rem;
  max-width: 80%;
  text-align: center;
  min-height: 4rem;
  margin-top: 1rem;
}

.category-pin {
  padding: 0.45rem;
  border-radius: 2rem;
  color: white;
  font-weight: 800;
}

.village-desc {
  font-size: 0.85rem;
  text-align: center;
  width: 80%;
  color: rgb(63, 63, 63);
}

.village-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: -2.5rem;
  margin-bottom: 1rem;
  min-height: 2rem;
  width: 100%;
  align-items: center;
  justify-content: center;

  height: 4.5rem;
}

.village-website > img {
  width: 1.5rem;
  height: 1.5rem;
}

.village-links {
  margin-bottom: 1.5rem;
}

.village-website {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.village-website > a {
  color: black;
  font-weight: 800;
  font-family: var(--font-family);
  text-decoration: none;
}

.village-website:hover {
  transform: translateY(-0.5rem);
  transition: all 0.2s ease-in-out;
}

.village-website:hover > img {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}
