.infopage {
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.infopage-map {
  width: 60rem;
  height: 40em;
  position: relative;
}

.infopage-map-container {
  width: 100%;
  height: 100%;
  margin-bottom: 6rem;
}

.spring50-info-window > h1 {
  font-size: 1rem;
}

.infopage > p {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding-top: 4rem;
}

.infopage-marker-cards {
  position: relative;
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
}

.infopage-marker-cards-category {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infopage-marker-cards-category > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.infopage-marker-cards-category > p {
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
  background: var(--yellow);
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 4px 4px 0px var(--yellow-darker);
  margin-left: -3rem;
  margin-bottom: 1rem;
}

.infopage-marker-card-flag {
  color: "black";
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.infopage-marker-card-flag > * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.infopage-marker-card-open > .infopage-marker-card-content {
  display: block;
}

.infopage-marker-card-flag > p {
  transition: all 0.1s ease-in-out;
}

.infopage-marker-card-flag:hover > p {
  transform: translate(0.75rem);

  transition: all 0.1s ease-in-out;
}

.infopage-marker-card-content > p {
  font-size: 1.2rem;
  color: rgba(93, 93, 93, 0.656);
}

.infopage-marker-card-content {
  margin-left: 2rem;
  margin-bottom: 1rem;
  display: none;
}

.infopage-marker-card-content > a {
  font-weight: 600;
  color: black;
  font-family: var(--font-family);
}

.infopage-mainplan {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.infopage-mainplan img {
  max-width: 100%;
}

.infopage-mainplan > div {
  width: 75%;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.infopage-mainplan > div > p:nth-child(2) {
  margin-top: 1rem;
}

.infopage > p:not(:nth-child(1)) {
  margin-top: 4rem;
}

.infopage-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infopage-contact > img {
  width: 10rem;
  border-radius: 50%;
}

.infopage-contact > p:nth-child(2) {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.infopage-contact > p:nth-child(3) {
  margin-bottom: 0.5rem;
}

.infopage-contact > p:nth-child(4) {
  max-width: 25rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.infopage-contacts {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  justify-content: space-around;
  row-gap: 4rem;
  column-gap: 4rem;
}

.infopage-contact > a {
  color: black;
}

.infopage-contact > a > p {
  color: black;
  font-weight: 600;
}
