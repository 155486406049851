@media screen and (max-width:900px) {

    .navbar-pages{
        display:none;
        position:absolute;
        flex-direction: column;
        top:calc(100%);
        left:0;
        width:100%;
        background:rgba(255, 255, 255, 0.962);
        align-items: center;
        padding-bottom:1rem;
        padding-top:1rem;
    }

    .deployed{
        display:flex;
        border-bottom: 2px solid black;
    }

    .navbar-left{
        gap:0.5rem;
    }

    .navbar-left > img:nth-child(2){
        display:block;
        width:1.75rem;
    }

    .navbar-left > img:nth-child(1){
        width:3rem
    }

    .navbar-right{
        gap:0.75rem;
    }

    .navbar-right > .button-classic{
        margin-left:0.25rem;
    }

    .navbar{
        width:100%;
        padding:0;
        margin:0;
        justify-content: center;
    }

    .navbar>img{
        display:block;
        bottom:-0.15rem;
    }
    .navbar-content{
        padding:0rem;
        width:100%;;
        display:flex;
        justify-content: space-between;
        gap:0rem;
        padding:1rem;
        position:relative;
    }

    .contact-pin{
        display:none;
    }

    .navbar-right>p:nth-child(1){
        display:none;
    }
}