.footer {
    width: 100%;
    overflow: hidden;
    height: 35rem;
    position: relative;
}

.footer>img:nth-child(1) {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.footer>img:nth-child(2) {
    width: 100%;
    position: absolute;
    bottom: 15rem;
}

.footer-infos {
    z-index: 10;
    position: absolute;
    bottom: 4rem;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40rem;
}

.footer-left a {
    display: flex;
    align-items: center;
    object-fit: cover;
    padding: 0.5rem;
    border: 1px solid white;
    border-radius: 50%;
}

.footer-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footer-left p {
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.footer-infos img {
    max-width: 2rem;
}

.footer-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer-right>a {
    text-decoration: none;
    color: white;
    font-weight: 700;
}

.footer-right>p {
    color: white;
}