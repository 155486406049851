.markdown-text {
  width: 100%;
}

.markdown-text > p {
  text-align: left;
  padding-left: 1rem;
}

.markdown-text > h1 {
  font-family: var(--font-family);
  font-size: 2rem;
  font-weight: 900;
  margin-top: 2rem;
}

.markdown-text > h2 {
  font-family: var(--font-family);
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  margin-top: 2rem;
  color: var(--blue);
  margin-bottom: 3rem;
}

.markdown-text > ul {
  margin-left: 3rem;
  margin-bottom: 2rem;
}

.markdown-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown-image > img {
  max-width: 80%;
}
