@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');


:root {
    --font-family: 'Manrope', sans-serif;
    --yellow: rgb(245, 220, 0);
    --yellow-darker: rgb(169, 154, 23);
    --blue: rgb(62, 191, 176);
    --blue-darker: rgb(35, 123, 113);
    --blue-middle: #039999;
    --red: #ff3333;
    --red-darker: #ba2525;
}