.spring50-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 8rem;
}

.spring50-title {
  font-weight: 600;
  font-size: 3rem;
}

.spring50-page > p:nth-child(2) {
  font-weight: 600;
  font-size: 1.5rem;
}

.spring50-showoff {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.spring50-showoff > img {
  width: 8rem;
  margin-bottom: 4rem;
}

.spring50-whatis {
  font-size: 2rem;
  font-weight: 900;
  width: 100%;
}

.spring50-parrains {
  margin-top: 4rem;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  height: 25rem;
  overflow: hidden;
}

.spring50-parrains-buttons {
  position: absolute;
  width: 100%;
  top: 0rem;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70%;
  margin-top: 6rem;
  margin-bottom: 3rem;
  z-index: 50;
}

.spring50-parrains-buttons > button {
  border: none;
  background: none;
}

.spring50-parrains-buttons > button:not(.blocked):hover {
  cursor: pointer;
  opacity: 0.8;
}

.spring50-parrains-buttons > button:nth-child(1) > svg {
  transform: rotate(180deg);
}

.parrain-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.parrain-card > img {
  border-radius: 20rem;
  max-width: 12rem;
  max-height: 12rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.808);
  margin-bottom: 1rem;
}

.parrain-card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0.2s;
}

.parrain-card-text > p:nth-child(1) {
  font-size: 2rem;
}

.parrain-card-text > p:nth-child(2) {
  width: 100%;
  text-align: center;
}

.parrain-card-text > div:nth-child(3) {
  height: 4px;
  width: 100%;
  background-color: rgba(101, 101, 101, 0.17);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.parrain-card-text > p:nth-child(4) {
  font-size: 1.5rem;
}

.parrain-card:not(.parrain-card-selected) > img {
  filter: blur(6px) brightness(1.2);
  transform: scale(0.8) translateY(2rem);
  transition: all 0.2s ease-in-out;
  box-shadow: none;
}

.parrain-card:not(.parrain-card-selected) > .parrain-card-text {
  opacity: 0;
  transition: all 0s;
}
